<script setup lang="ts">
  import type { PloneSortOption } from '~/types/Plone'

  const props = defineProps<{
    options: PloneSortOption[]
  }>()

  const router = useRouter()
  const route = useRoute()

  const defaultOption = props.options.find((option) => option['default'])
  const defaultSort = `${defaultOption?.value?.sort_on}:${defaultOption?.value?.sort_order}`
  const options = Object.fromEntries(props.options.map(o => ([`${o.value?.sort_on}:${o.value?.sort_order}`, o.label])))
</script>

<template>
  <app-form
    :reset-params="['page']"
    route-query
    :schema="[{
      $formkit: 'select',
      name: 'sort',
      options: options,
      value: defaultSort
    }]"/>
</template>

<style
  scoped
 >
</style>
